// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { createTheme } from "@mui/material";
import { Theme, ThemeOptions } from "@mui/system";
import { deepmerge } from "@mui/utils";

import { Colors } from "./colors";
import { Colors as ColorsTwentyFive } from "./colors-twentyfive";
import components, { AllComponents } from "./components";
import themePalette from "./palette";
import { createBreakpoints } from "@mui/system";
import { uiContainerTransitionMs, uiOpacityHover } from "@src/utils";

const themeFonts = () => {
  return {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  };
};

export function getThemeOptions(): { components: AllComponents } {
  return {
    components: components(Colors),
  };
}

export const getPaletteThemeOptions = () => {
  return {
    palette: themePalette(Colors),
  };
};

export const getTheme = (
  options: { themeOverride?: ThemeOptions } = {},
): Theme => {
  const theme_ = createTheme(
    deepmerge(
      {
        palette: themePalette(Colors),
        components: components(Colors),
        typography: themeFonts(),
      },
      options.themeOverride,
    ),
  );

  return theme_;
};

const breakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1236,
      ll: 1320,
      xl: 1536,
    },
  },
};
const theme = getTheme({ themeOverride: { ...breakpoints } });

export default theme;

export const getTwentyFourTheme = (theme: any) => {
  return createTheme({
    ...theme,
    breakpoints: createBreakpoints({
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    }),
    typography: {
      ...theme.typography,
      fontFamily: "Inter",
      allVariants: {
        ...theme.typography.allVariants,
        fontFamily: "Inter",
      },
    },
    components: {
      MuiButton: {
        ...theme.components.MuiButton,
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        variants: [
          {
            props: { variant: "containedInverse" },
            style: {
              ...theme.components?.MuiButton?.styleOverrides?.contained,
              color: Colors.PrimaryMain,
              backgroundColor: Colors.PrimaryContrast,
              borderRadius: "8px",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Inter",
              padding: "16px 32px",
            },
          },
        ],
        styleOverrides: {
          ...theme.components?.MuiButton?.styleOverrides,
          fontFamily: "Inter",
          root: {
            ...theme.components?.MuiButton?.styleOverrides?.root,
            color: Colors.PrimaryContrast,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            padding: "16px 32px",
          },
          contained: {
            ...theme.components?.MuiButton?.styleOverrides?.contained,
            color: Colors.PrimaryContrast,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            padding: "16px 32px",
          },
        },
      },
      MuiTypography: {
        ...theme.components.MuiTypography,
        defaultProps: {
          ...theme.components.MuiTypography.defaultProps,
        },
        styleOverrides: {
          ...theme.components.MuiTypography.styleOverrides,
          h1: {
            ...theme.components.MuiTypography.h1,
            fontFamily: "Kanit",
            fontWeight: 500,
            fontSize: "70px",
            letterSpacing: "-1.75px",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          h2: {
            ...theme.components.MuiTypography.h2,
            fontFamily: "Kanit",
            fontWeight: 600,
            lineHeight: "50px",
            fontSize: "48px",
            color: Colors.TextPrimary,
          },
          h3: {
            ...theme.components.MuiTypography.h3,
            fontFamily: "Kanit",
            fontSize: "44px",
            lineHeight: "46px",
            [theme.breakpoints.up("sm")]: {
              fontSize: "48px",
              lineHeight: "50px",
            },
            color: Colors.TextPrimary,
          },
          h4: {
            ...theme.components.MuiTypography.h4,
            fontFamily: "Kanit",
            fontSize: "19px",
            lineHeight: "150%",
            color: Colors.TextPrimary,
            fontWeight: 500,
          },
          h5: {
            ...theme.components.MuiTypography.h5,
            fontFamily: "Kanit",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          h6: {
            ...theme.components.MuiTypography.h6,
            fontFamily: "Kanit",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          subtitle1: {
            ...theme.components.MuiTypography.subtitle1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            letterSpacing: 0,
          },
          subtitle2: {
            ...theme.components.MuiTypography.subtitle2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            letterSpacing: 0,
          },
          body1: {
            ...theme.components.MuiTypography.body1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            lineHeight: "175%",
            letterSpacing: 0,
            color: Colors.BodyText,
          },
          body2: {
            ...theme.components.MuiTypography.body2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: 0,
            color: Colors.BodyText,
          },
          caption: {
            ...theme.components.MuiTypography.caption,
            fontFamily: "Inter",
            textTransform: "uppercase",
            color: Colors.HomeBodyText,
            fontSize: "12px",
            [theme.breakpoints.up("md")]: {
              fontSize: "14px",
            },
            fontWeight: 500,
            letterSpacing: 2.8,
          },
          button: {
            ...theme.components.MuiTypography.button,
            color: Colors.PrimaryContrast,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "200%",
            textTransform: "capitalize",
          },
          overline: {
            ...theme.components.MuiTypography.overline,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
            letterSpacing: "0.0625rem",
            textTransform: "uppercase",
          },
        },
      },
    },
  });
};

export const getTwentyFiveTheme = (theme: any) => {
  return createTheme({
    ...theme,
    breakpoints: createBreakpoints({
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    }),
    typography: {
      ...theme.typography,
      fontFamily: "Inter",
      allVariants: {
        ...theme.typography.allVariants,
        fontFamily: "Inter",
      },
    },
    components: {
      MuiTooltip: {
        ...theme.components.MuiTooltip,
        styleOverrides: {
          tooltip: {
            backgroundColor: `rgba(0, 0, 0, 0.87)`,
            color: ColorsTwentyFive.PrimaryContrast,
          },
        },
      },
      MuiButton: {
        ...theme.components.MuiButton,
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        variants: [
          {
            props: { variant: "containedInverse" },
            style: {
              ...theme.components?.MuiButton?.styleOverrides?.contained,
              color: ColorsTwentyFive.PrimaryMain,
              backgroundColor: ColorsTwentyFive.PrimaryContrast,
              borderRadius: "8px",
              fontWeight: 400,
              fontSize: "15px",
              fontFamily: "Inter",
              padding: "16px 32px",
            },
          },
        ],
        styleOverrides: {
          ...theme.components?.MuiButton?.styleOverrides,
          fontFamily: "Inter",
          root: {
            ...theme.components?.MuiButton?.styleOverrides?.root,
            color: ColorsTwentyFive.PrimaryContrast,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            paddingLeft: "32px",
            paddingRight: "32px",
            fontSize: "20px",
            opacity: 1,
            transition: `opacity ${uiContainerTransitionMs}ms ease-in-out`,
            "&:hover": {
              opacity: uiOpacityHover,
              color: ColorsTwentyFive.TextPrimary,
              textDecoration: "none",
            },
          },
          contained: {
            ...theme.components?.MuiButton?.styleOverrides?.contained,
            color: ColorsTwentyFive.TextPrimary,
            background: ColorsTwentyFive.PrimaryGradient,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 500,
            paddingLeft: "32px",
            paddingRight: "32px",
          },
          outlined: {
            ...theme.components?.MuiButton?.styleOverrides?.outlined,
            color: ColorsTwentyFive.TextPrimary,
            backgroundColor: "transparent",
            boxShadow: `inset 0px 0px 0px 1px ${ColorsTwentyFive.TextPrimary}`,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            paddingLeft: "32px",
            paddingRight: "32px",
            "&:hover": {
              boxShadow: `inset 0px 0px 0px 1px ${ColorsTwentyFive.TextPrimary}`,
            },
          },
        },
      },
      MuiLink: {
        ...theme.components.MuiLink,
        styleOverrides: {
          root: {
            color: ColorsTwentyFive.TextPrimary,
            fontFamily: "Inter",
            opacity: 1,
            transition: `opacity ${uiContainerTransitionMs}ms ease-in-out`,
            "&:hover": {
              opacity: uiOpacityHover,
              color: ColorsTwentyFive.TextPrimary,
              textDecoration: "none",
            },
          },
        },
      },
      MuiTypography: {
        ...theme.components.MuiTypography,
        styleOverrides: {
          ...theme.components.MuiTypography.styleOverrides,
          h1: {
            ...theme.components.MuiTypography.h1,
            fontFamily: "Inter",
            fontWeight: 800,
            letterSpacing: "-1.75px",
            fontSize: "70px",
            lineHeight: "72px",
            [theme.breakpoints.down("md")]: {
              fontSize: "64px",
              lineHeight: "66px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "56px",
              lineHeight: "58px",
            },
            color: ColorsTwentyFive.TextPrimary,
          },
          h2: {
            ...theme.components.MuiTypography.h2,
            fontFamily: "Inter",
            fontWeight: 800,
            letterSpacing: "-1.75px",
            lineHeight: "50px",
            fontSize: "48px",
            [theme.breakpoints.down("md")]: {
              fontSize: "42px",
              lineHeight: "44px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "38px",
              lineHeight: "40px",
            },
            color: ColorsTwentyFive.TextPrimary,
          },
          h3: {
            ...theme.components.MuiTypography.h3,
            fontFamily: "Inter",
            letterSpacing: "-1.75px",
            fontSize: "44px",
            lineHeight: "46px",
            [theme.breakpoints.down("md")]: {
              fontSize: "36px",
              lineHeight: "38px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "32px",
              lineHeight: "34px",
            },
            color: ColorsTwentyFive.TextPrimary,
          },
          h4: {
            ...theme.components.MuiTypography.h4,
            fontFamily: "Inter",
            fontSize: "26px",
            letterSpacing: "-1.75px",
            lineHeight: "150%",
            color: ColorsTwentyFive.InfoLight,
            fontWeight: 800,
          },
          h5: {
            ...theme.components.MuiTypography.h5,
            fontFamily: "Inter",
            letterSpacing: "-1.75px",
            lineHeight: "150%",
            color: ColorsTwentyFive.TextPrimary,
          },
          h6: {
            ...theme.components.MuiTypography.h6,
            fontFamily: "Inter",
            letterSpacing: "-1.75px",
            lineHeight: "150%",
            color: ColorsTwentyFive.TextPrimary,
          },
          subtitle1: {
            ...theme.components.MuiTypography.subtitle1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            letterSpacing: 0,
          },
          subtitle2: {
            ...theme.components.MuiTypography.subtitle2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            letterSpacing: 0,
          },
          body1: {
            ...theme.components.MuiTypography.body1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            lineHeight: "32px",
            letterSpacing: 0,
            color: ColorsTwentyFive.BodyText,
          },
          body2: {
            ...theme.components.MuiTypography.body2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: 0,
            color: ColorsTwentyFive.BodyText,
          },
          caption: {
            ...theme.components.MuiTypography.caption,
            fontFamily: "Inter",
            textTransform: "uppercase",
            color: ColorsTwentyFive.TextPrimary,
            fontSize: "12px",
            [theme.breakpoints.up("md")]: {
              fontSize: "14px",
            },
            fontWeight: 500,
            letterSpacing: 2.8,
          },
          button: {
            ...theme.components.MuiTypography.button,
            color: ColorsTwentyFive.PrimaryContrast,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "200%",
            textTransform: "capitalize",
          },
          overline: {
            ...theme.components.MuiTypography.overline,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
            letterSpacing: "0.0625rem",
            textTransform: "uppercase",
          },
        },
      },
    },
  });
};
