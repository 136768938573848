// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { Colors as OriginalColors } from "./colors";

export const Colors = { ...OriginalColors };

(Colors as any)["BackgroundBody"] = "#112234";
(Colors as any)["PrimaryContainerDark"] = "#071320";
(Colors as any)["HeadingText"] = "#D791FF";
(Colors as any)["TextPrimary"] = "#FFFFFF";
(Colors as any)["BodyText"] = "#FFFFFF"; // TODO: Find a medium between FFF and C0C7CB
(Colors as any)["InfoLight"] = "#ACE9FF";
(Colors as any)["PrimaryGradient"] =
  "linear-gradient(90deg, #C45CFF 0%, #A200FF 100%)";
