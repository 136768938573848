// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { RefObject, useEffect, useState } from "react";
import { Colors } from "./gatsby-theme-material-ui-top-layout/colors";
import { MenuLink } from "./types";
import { keyframes } from "@emotion/react";

export const camelize = (str: string) => {
  str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

export const NAMED_COLORS: { [key: string]: string } = {
  white: Colors.PrimaryContrast,
  blue: Colors.LightBlue,
  grey: Colors.BlueGrey,
  body: Colors.BackgroundBody,
  transparent: "transparent",
};

export const getEnvironmentName = (): string => {
  const domain = process.env["GATSBY_PANGEA_BASE_DOMAIN"] || "";

  if (domain.startsWith("dev")) {
    return "dev";
  } else if (domain.startsWith("stg") || domain.startsWith("staging")) {
    return "stg";
  }

  return "lve";
};

export const useIntersection = (
  element: RefObject<HTMLElement | undefined>,
  rootMargin: string,
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );

    element.current && observer.observe(element.current);

    return () => {
      if (element.current) {
        observer.unobserve(element.current);
      }
    };
  }, []);

  return isVisible;
};

export const defaultBlogImage: string =
  "https://cdn.hashnode.com/res/hashnode/image/upload/v1683525272978/MB5H_kgOC.png?w=1600&h=840&fit=crop&crop=entropy&auto=format&auto=compress,format&format=webp";

export const screenSizeLgMax = 1296;
export const screenSizeXlMax = 1776;

export const primaryGradientStyle =
  "linear-gradient(75deg, rgba(230,183,163,1) 29%, rgba(0,167,255,1) 48%, rgba(212,144,251,1) 57%, rgba(0,167,255,1) 75%, rgba(215,182,168,1) 88%);";

export const tileBorderGradientStyle =
  "linear-gradient(30deg, rgba(85,27,118,1) 9%, rgba(0,167,255,1) 41%, rgba(183,195,242,1) 52%, rgba(247,203,185,1) 61%, rgba(214,237,255,1) 80%, rgba(0,167,255,1) 93%);";

export const simpleGradientStyle =
  "linear-gradient(270deg, rgba(40,2,115,1) 9%, rgba(85,27,118,1) 93%);";

export const aiGradientStyle =
  "linear-gradient(90deg, #027EB9 -5%, #947AFE  100%)";

export const goldGradientStyle =
  "linear-gradient(90deg, #FAE085 0%, #9E7F28 100%)";

// Experimenting with this instead of exporting a new set of icons in a different color
export const svgToHeaderTextColor =
  "brightness(0) saturate(100%) invert(15%) sepia(17%) saturate(3423%) hue-rotate(196deg) brightness(98%) contrast(89%)";

export const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? wrapper(children) : children;

export const signupEmailValidationHelperText =
  "You must enter a valid email address to continue";

/**
 * Tests if the first time provided is after
 * the second time provided
 *
 * @param time1
 * @param time2
 * @returns boolean
 */
export function compareTime(time1: Date, time2: Date) {
  return new Date(time1) > new Date(time2);
}

export const setCookie = (
  name: string,
  value: string,
  options?: {
    age?: number;
    policy?: string;
    domain?: string;
  },
) => {
  const defaults = {
    age: 31536000,
    policy: "strict",
    domain: window.location.hostname,
  };
  const options_ = {
    ...defaults,
    ...options,
  };

  document.cookie = `${name}=${value}; samesite=${options_.policy}; path=/; domain=${options_.domain}; max-age=${options_.age}; secure`;
};

export const getCookie = (name: string): string => {
  const cookieValue =
    document.cookie
      .split("; ")
      .find((row) => row.startsWith(name))
      ?.split("=")[1] || "";

  return cookieValue;
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}= ;expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const LOCAL_STORAGE_KEYS = {
  popupRedirectProductHuntMarch262024: "popup-product-hunt-mar-26-2024",
  popupRedirectGartnerReportSep092024: "popup-gartner-report-sep-09-2024",
  popupRedirectAiChallengeOne: "popup-ai-challenge-feb-20-2025",
};

export const uiContainerTransitionMs = 150;
export const uiOpacityHover = 0.5;

export const fadeIn = keyframes`
  0% { opacity: 0 }
  50% { opacity: 0 }
  100% { opacity: 1 }
`;

export const fadeOut = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0 }
  100% { opacity: 0 }
`;

export const progressBar = keyframes`
  0% { width: 10px }
  95% { width: 150px }
  100% { width: 150px }
`;

export const accentColors: {
  [colorName: string]: string;
} = {
  "sky-blue": Colors.PrimaryAccentSkyBlue,
  green: Colors.PrimaryAccentGreen,
  blue: Colors.PrimaryAccentBlue,
  violet: Colors.PrimaryAccentViolet,
  periwinkle: Colors.PrimaryAccentPeriwinkle,
  "violet-inverse": Colors.PrimaryAccentAi,
};

export const accentBackgrounds: {
  [colorName: string]: string;
} = {
  "sky-blue": `linear-gradient(180deg, #FDFDFF 0%, #EBF7FE 100%)`,
  green: `linear-gradient(180deg, #FDFDFF 0%, #E9F8F1 100%)`,
  blue: `linear-gradient(180deg, #FDFDFF 0%, #EBF5FF 100%)`,
  violet: `linear-gradient(180deg, #FDFDFF 0%, #F1ECFE 100%)`,
  periwinkle: `linear-gradient(180deg, #FDFDFF 0%, #F1F3FD 100%)`,
};

export const filterFooterNavItems = (item: MenuLink) => {
  if (item?.footerOnly === true) {
    return false;
  }

  return true;
};

export interface QuoteData {
  quote: string;
  image: string;
  caption: string;
  url: string;
  authorTitle: string;
  authorName: string;
  company: string;
  companyLogo: string;
}

export interface QuoteProps {
  quote: QuoteData;
}

export const QUOTES: Array<QuoteData> = [
  {
    quote:
      "“It was simple to integrate Pangea’s Secure Audit Log to get signed and verified logs with just a few lines of code. It was a no-brainer.”",
    image: "/case-studies/Garrett_Hamilton.png",
    companyLogo: "/case-studies/reach-logo.svg",
    caption: "— Garrett Hamilton, Founder & CEO, Reach",
    url: "/case-studies/reach-security/",
    authorTitle: "Founder & CEO",
    authorName: "Garrett Hamilton",
    company: "Reach",
  },
  {
    quote:
      "“In any social platform, unconstrained user generated content erodes trust. Pangea Redact solves this problem.”",
    image: "/case-studies/Arvind_Singh.png",
    companyLogo: "/case-studies/fashmates-logo.svg",
    caption: "— Arvind Singh, COO, Fashmates",
    url: "/case-studies/fashmates/",
    authorTitle: "COO",
    authorName: "Arvind Singh",
    company: "Fashmates",
  },
  {
    quote:
      "“For Codex, we realized early on that security is in Pangea’s DNA and we were glad for them to do the heavy lifting on the SOC 2 Type II and HIPAA attestations.”",
    image: "/case-studies/Zane_Silver.png",
    companyLogo: "/case-studies/codex-logo.svg",
    caption: "— Zane Silver, Head of Engineering",
    url: "/case-studies/codex/",
    authorTitle: "Head of Engineering",
    authorName: "Zane Silver",
    company: "Codex",
  },
  {
    quote:
      "“Pangea makes SiteMana’s data cleaner, it improves our product and increases our customer retention rate, which is the single most important factor for us.”",
    image: "/case-studies/Peter_Ma.png",
    companyLogo: "/case-studies/sitemana-logo.svg",
    caption: "— Peter Ma, Co-founder",
    url: "/case-studies/sitemana/",
    authorTitle: "Co-founder",
    authorName: "Peter Ma",
    company: "SiteMana",
  },
];

export const QUOTES_EXTENDED: Array<QuoteData> = [
  {
    quote: "",
    image: "",
    companyLogo: "/case-studies/scanmysms.svg",
    caption: "",
    url: "/case-studies/scan-my-sms/",
    authorTitle: "",
    authorName: "",
    company: "Scan My SMS",
  },
  {
    quote: "",
    image: "",
    companyLogo: "/case-studies/radius.svg",
    caption: "",
    url: "/case-studies/radius-xr/",
    authorTitle: "",
    authorName: "",
    company: "RadiusXR",
  },
];

/**
 * Configuration for supporting many region-specific banners in
 * one implementation
 *
 * https://support.cookiebot.com/hc/en-us/articles/360010932419-Supporting-multiple-legislations-on-the-same-website
 */
const CB_REGION_EU = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];

const CB_REGION_US_CALIFORNIA = ["US-06"];

const CB_REGION_ID_EU = "d35a8c65-8dbc-44f2-bc1a-b241437f9dcd";
const CB_REGION_ID_US_CALIFORNIA = "5bdde89d-4b4e-41d3-9778-0b22393f6bf1";

export const CB_REGION_ID_GLOBAL = "1f62e837-77e8-4118-b664-417fef0d7177";
export const CB_REGIONS = `[
  {'region': '${CB_REGION_US_CALIFORNIA}', 'cbid': '${CB_REGION_ID_US_CALIFORNIA}'},
  {'region': '${CB_REGION_EU.join(", ")}', 'cbid': '${CB_REGION_ID_EU}'}
]`;

declare namespace cookiebot {
  class Cookiebot {
    consent: {
      statistics: boolean;
      necessary: boolean;
      preferences: boolean;
      marketing: boolean;
    };
    renew: () => null;
  }
}

export declare const Cookiebot: cookiebot.Cookiebot;

export type ResumableInterval = {
  start: () => void;
  pause: () => void;
  resume: () => void;
  stop: () => void;
};

/**
 * Creates a helper object which manages an interval-like timer which can be paused and resumed
 *
 * @param callback - Function to call when the interval has passed
 * @param delay - The delay between intervals
 * @returns ResumableInterval
 */
export function createResumableInterval(
  callback: () => void,
  delay: number = 0,
): ResumableInterval {
  let timeoutId: any;
  let paused = false;
  let startTime = 0;
  let pausedTime = 0;
  let timeRemaining = delay;

  const resetHelper = () => {
    startTime = Date.now();
    pausedTime = Date.now();
    timeRemaining = delay;
  };

  const intervalHandler = () => {
    if (!paused) {
      resetHelper();

      paused = false;

      callback();

      timeoutId = setTimeout(intervalHandler, delay);
    }
  };

  const start = () => {
    if (!paused) {
      resetHelper();

      timeoutId = setTimeout(intervalHandler, delay);
    }
  };

  const pause = () => {
    if (!paused) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = null;

      pausedTime = Date.now();

      timeRemaining = timeRemaining - (pausedTime - startTime);

      paused = true;
    }
  };

  const resume = () => {
    if (paused) {
      paused = false;
      startTime = Date.now();

      timeoutId = setTimeout(intervalHandler, timeRemaining);
    }
  };

  const stop = () => {
    clearTimeout(timeoutId);
  };

  return { start, pause, resume, stop };
}

export function getLanguageLabel(str: string): string {
  const lowercased = str.toLowerCase();

  switch (lowercased) {
    case "golang":
    case "go":
      return "Go";
    case "python":
    case "py":
      return "Python";
    case "javascript":
    case "js":
      return "Javascript";
    case "java":
      return "Java";
    case "bash":
      return "Bash";
    case "curl":
      return "cURL";
    case "csharp":
    case "c#":
    case "dotnet":
      return "C#";
    default:
      return lowercased;
  }
}

// Monday March 3rd 12:00am ET (1740978000000)
export const AI_CHALLENGE_START_TIME = 1740978000000;
// export const AI_CHALLENGE_START_TIME = 40978000000; // testing
